import {
    ACT_STATE,
    NSW_STATE,
    QLD_STATE,
    SA_STATE,
    SUB_LEASE_TYPE_LONG_TERM_RENEWAL,
    SUB_LEASE_TYPE_SA_RENEWALS,
    VIC_STATE
} from '../config';
import { AGENCY_ACCOUNT_TYPE, GENERAL_ACCOUNT_TYPE, integration as integrationCompanies } from '../constants/constants';
import { isDocumentType, isLeaseTypeCommercialLease, isLeaseTypePm, isLeaseTypeSales } from './agreementUtils';

export const DEFAULT = 'default';

export const getResTenLabel = (label, location) => {
    if (resTenLabels[label][location]) {
        return resTenLabels[label][location];
    }
    return resTenLabels[label][DEFAULT];
};

export const getLabel = (label, location, leaseType) => {
    let labelObject = resTenLabels;
    if (isLeaseTypeSales(leaseType)) {
        labelObject = salesLabels;
    } else if (isLeaseTypePm(leaseType)) {
        labelObject = pmLabels;
    } else if (isLeaseTypeCommercialLease(leaseType)) {
        labelObject = commercialLeaseLabels;
    } else if (isDocumentType(leaseType)) {
        labelObject = documentLabels;
    } else if (leaseType === SUB_LEASE_TYPE_SA_RENEWALS) {
        labelObject = renewalLabels;
    }
    if (labelObject[label][location]) {
        return labelObject[label][location];
    }
    return labelObject[label][DEFAULT];
};

export const getGeneralAppLabel = (label, accountType) => {
    return generalAppLabels[label][accountType];
};

export const getSubLeaseTypeLabel = subLeaseType => {
    switch (subLeaseType) {
        case SUB_LEASE_TYPE_LONG_TERM_RENEWAL:
            return 'Lease Extension';
        default:
            return '';
    }
};

export const CANCEL_SIGNING_MESSAGE = `Are you sure want to move this to drafts? All progress and signatures will be lost.`;

export const resTenLabels = {
    dateStart: {
        [VIC_STATE]: `Date first rent payment is due:`,
        [DEFAULT]: 'Payable in advance starting on:'
    },
    dateStartWithoutColon: {
        [VIC_STATE]: `Date first rent payment is due`,
        [DEFAULT]: 'Payable in advance starting on'
    },
    landlord: {
        [VIC_STATE]: `Rental provider`,
        [ACT_STATE]: 'Lessor',
        [QLD_STATE]: 'Lessor',
        [DEFAULT]: 'Landlord'
    },
    landlordPoolMaintenance: {
        [VIC_STATE]: `Rental provider`,
        [DEFAULT]: 'Owner'
    },
    landlordLower: {
        [VIC_STATE]: `rental provider`,
        [ACT_STATE]: 'lessor',
        [QLD_STATE]: 'lessor',
        [DEFAULT]: 'landlord'
    },
    addLandlord: {
        [VIC_STATE]: `Add rental provider`,
        [ACT_STATE]: 'Add Lessor',
        [QLD_STATE]: 'Add Lessor',
        [DEFAULT]: 'Add landlord'
    },
    tenant: {
        [VIC_STATE]: `Renter`,
        [DEFAULT]: 'Tenant'
    },
    tenantLower: {
        [VIC_STATE]: `renter`,
        [DEFAULT]: 'tenant'
    },
    tenants: {
        [VIC_STATE]: `Renters`,
        [DEFAULT]: 'Tenants'
    },
    tenantsLower: {
        [VIC_STATE]: `renters`,
        [DEFAULT]: 'tenants'
    },
    addTenant: {
        [VIC_STATE]: `Add renter`,
        [DEFAULT]: 'Add tenant'
    },
    eft: {
        [VIC_STATE]: `Bank Deposit`,
        [SA_STATE]: `Internet Transfer`,
        [DEFAULT]: 'EFT'
    },
    other: {
        [VIC_STATE]: `Other electronic form of payment, including Centrepay`,
        [DEFAULT]: 'Other'
    },
    tenantPet: {
        [VIC_STATE]: `Will the renter have a pet?`,
        [DEFAULT]: 'Will the tenant have a pet?'
    },
    tenancy: {
        [VIC_STATE]: `Rental`,
        [DEFAULT]: 'Tenancy'
    },
    servicesOfNotices: {
        [QLD_STATE]: `Services of notices should be sent to:`,
        [ACT_STATE]: 'Address for service of all documents relating to the Tenancy:',
        [SA_STATE]: 'services of documents:',
        [DEFAULT]: 'Consent to electronic services of notices:'
    },
    servicesOfNoticesAddress: {
        [NSW_STATE]: 'NO',
        [VIC_STATE]: `NO`,
        [DEFAULT]: 'Address'
    },
    servicesOfNoticesEmail: {
        [NSW_STATE]: 'YES',
        [VIC_STATE]: `YES`,
        [DEFAULT]: 'Emails'
    },
    addressForServicesOfNotices: {
        [SA_STATE]: 'Address for service of documents',
        [DEFAULT]: 'Address for services of notices:'
    },
    maxNumberOfOccupants: {
        [ACT_STATE]: 'Number of persons permitted',
        [DEFAULT]: 'Max number of occupants'
    },
    maximumNumberOfOccupants: {
        [ACT_STATE]: 'Number of persons permitted',
        [DEFAULT]: 'Maximum number of occupants'
    },
    occupants: {
        [ACT_STATE]: 'persons permitted',
        [DEFAULT]: 'occupants'
    },
    occupant: {
        [ACT_STATE]: 'Permitted person',
        [DEFAULT]: 'Occupant'
    },
    licenceNumber: {
        [SA_STATE]: 'RLA Number',
        [DEFAULT]: 'Corporation Licence number'
    },
    rentPerWeek: {
        [SA_STATE]: 'The rent per week is',
        [DEFAULT]: 'Rent per week is'
    },
    payable: {
        [DEFAULT]: 'Payable'
    },
    effectiveFrom: {
        [SA_STATE]: 'Commencing on:',
        [DEFAULT]: 'Effective from:'
    },
    noticeEmailAddress: {
        [VIC_STATE]: 'Email address for electronic service of notices consent:',
        [DEFAULT]: 'Email address for services notices:'
    },
    rentTitle: {
        [SA_STATE]: 'Rent and Bond',
        [DEFAULT]: 'Rent'
    },
    tenantEmail: {
        [SA_STATE]: 'Email address for the service of documents',
        [DEFAULT]: 'Email'
    },
    landlordAddress: {
        [SA_STATE]: "Address for service of documents (which cannot be the Agent's address for service)",
        [DEFAULT]: 'Address (For casual leasing only)'
    },
    landlordCorporationAddress: {
        [SA_STATE]: "Address for service of documents (which cannot be the Agent's address for service)",
        [DEFAULT]: 'Corporation business address'
    },
    createLease: {
        [DEFAULT]: 'Create new lease'
    },
    signorsHeader: {
        [DEFAULT]: 'tenant(s)'
    },
    signorHeader: {
        [DEFAULT]: 'tenant'
    },
    mobileViewState: {
        [NSW_STATE]: 'New South Wales',
        [QLD_STATE]: 'Queensland',
        [DEFAULT]: ''
    },
    termQty: {
        [DEFAULT]: 'A term of:'
    },
    termStartDate: {
        [DEFAULT]: 'The Lease will start on'
    },
    termEndDate: {
        [DEFAULT]: 'and finish date is'
    },
    resendDocName: {
        [DEFAULT]: 'lease'
    },
    signatoryName: {
        [VIC_STATE]: `renter`,
        [DEFAULT]: 'tenant'
    },
    signatoryNamePlural: {
        [VIC_STATE]: `renters`,
        [DEFAULT]: 'tenants'
    },
    resendLeaseType: {
        [VIC_STATE]: `Residential Rental`,
        [DEFAULT]: 'Residential Tenancy'
    },
    otherExpenses: {
        [DEFAULT]: 'Other expenses and charges.'
    },
    service: {
        [DEFAULT]: 'Service'
    },
    estimate: {
        [DEFAULT]: 'Estimate'
    },
    dueDate: {
        [DEFAULT]: 'Due date'
    },
    rebateAmount: {
        [DEFAULT]: 'Amount'
    }
};

export const salesLabels = {
    tenant: {
        [DEFAULT]: 'Vendor',
        [QLD_STATE]: 'Client'
    },
    tenantLower: {
        [DEFAULT]: 'vendor',
        [QLD_STATE]: 'client'
    },
    createLease: {
        [DEFAULT]: 'Create new agreement'
    },
    disclosureTitle: {
        [QLD_STATE]:
            'Agent’s rebate, discount, commission or benefit incurred in the provision of or performance of the service',
        [DEFAULT]: 'Disclosure of Rebates, Discounts, Commissions or other Benefits'
    },
    signorsHeader: {
        [DEFAULT]: 'vendor(s)'
    },
    signorHeader: {
        [DEFAULT]: 'vendor'
    },
    resendDocName: {
        [DEFAULT]: 'agreement'
    },
    signatoryName: {
        [DEFAULT]: 'vendor'
    },
    signatoryNamePlural: {
        [DEFAULT]: 'vendors'
    },
    resendLeaseType: {
        [DEFAULT]: 'Sales'
    },
    otherExpenses: {
        [QLD_STATE]: 'Fees and Charges',
        [DEFAULT]: 'Other expenses and charges.'
    },
    service: {
        [QLD_STATE]: 'Description',
        [DEFAULT]: 'Service'
    },
    estimate: {
        [QLD_STATE]: 'Amount',
        [DEFAULT]: 'Estimate'
    },
    dueDate: {
        [QLD_STATE]: 'When payable',
        [DEFAULT]: 'Due date'
    },
    rebateAmount: {
        [QLD_STATE]: 'Estimated Amount',
        [DEFAULT]: 'Amount'
    },
    identityImages: {
        [QLD_STATE]: 'Require clients(s) to upload proof of identity',
        [DEFAULT]: 'Require vendor(s) to upload proof of identity'
    }
};

export const pmLabels = {
    landlord: {
        [VIC_STATE]: 'Client',
        [QLD_STATE]: 'Lessor',
        [DEFAULT]: 'Landlord'
    },
    tenant: {
        [QLD_STATE]: 'Lessor',
        [DEFAULT]: 'Landlord'
    },
    tenantLower: {
        [QLD_STATE]: 'lessor',
        [DEFAULT]: 'landlord'
    },
    createLease: {
        [DEFAULT]: 'Create new agreement'
    },
    rentAndPayment: {
        [VIC_STATE]: 'Bank Account Information',
        [DEFAULT]: 'Rent & Payment'
    },
    disclosureTitle: {
        [VIC_STATE]: 'Disclosure of Rebates and Commissions',
        [QLD_STATE]: 'Disclosure of Rebates, Discounts, Commissions or other Benefits [Clause 20]',
        [DEFAULT]: 'Disclosure of Rebates, Discounts, Commissions or other Benefits'
    },
    'contacts.limitOnCostsOfRepairs': {
        [QLD_STATE]:
            'The maximum value of repairs and maintenance to be paid by the agent without prior approval by the client is',
        [DEFAULT]: 'Expenditure limit on costs of repairs before approval required'
    },
    'landlord.persons.corporationName': {
        [VIC_STATE]: 'Company Name',
        [DEFAULT]: 'Corporation name'
    },
    'landlord.persons.corporationAddress': {
        [SA_STATE]: 'Company Registered Address',
        [DEFAULT]: 'Corporation address'
    },
    signorsHeader: {
        [QLD_STATE]: 'lessor(s)',
        [DEFAULT]: 'landlord(s)'
    },
    signorHeader: {
        [QLD_STATE]: 'lessor',
        [DEFAULT]: 'landlord'
    },
    landlords: {
        [QLD_STATE]: 'lessors',
        [DEFAULT]: 'landlords'
    },
    Landlords: {
        [QLD_STATE]: 'Lessors',
        [DEFAULT]: 'Landlords'
    },
    resendDocName: {
        [DEFAULT]: 'agreement'
    },
    signatoryName: {
        [DEFAULT]: 'landlord'
    },
    signatoryNamePlural: {
        [DEFAULT]: 'landlords'
    },
    resendLeaseType: {
        [DEFAULT]: 'Property Management'
    },
    otherExpenses: {
        [DEFAULT]: 'Other expenses and charges.'
    },
    service: {
        [DEFAULT]: 'Service'
    },
    estimate: {
        [DEFAULT]: 'Estimate'
    },
    dueDate: {
        [DEFAULT]: 'Due date'
    },
    rebateAmount: {
        [DEFAULT]: 'Amount'
    },
    identityImages: {
        [QLD_STATE]: 'Require lessor(s) to upload proof of identity',
        [VIC_STATE]: 'Require client(s) to upload proof of identity',
        [DEFAULT]: 'Require landlord(s) to upload proof of identity'
    },
    companyName: {
        [SA_STATE]: 'Trading as',
        [DEFAULT]: 'Company Name'
    },
    licenceNo: {
        [SA_STATE]: 'RLA Number',
        [DEFAULT]: 'Licence No'
    },
    eft: {
        [SA_STATE]: 'EFT',
        [DEFAULT]: 'EFT'
    },
    duties: {
        [SA_STATE]: 'Charges to Tenants',
        [DEFAULT]: 'Duties'
    }
};

export const commercialLeaseLabels = {
    tenant: {
        [DEFAULT]: 'Lessee'
    },
    tenantLower: {
        [DEFAULT]: 'lessee'
    },
    tenantEmail: {
        [DEFAULT]: 'Email'
    },
    createLease: {
        [DEFAULT]: 'Create new lease'
    },
    signorsHeader: {
        [DEFAULT]: 'lessee(s)'
    },
    signorHeader: {
        [DEFAULT]: 'lessee'
    },
    termQty: {
        [DEFAULT]: 'A term of:'
    },
    termStartDate: {
        [DEFAULT]: 'Commencing On'
    },
    termEndDate: {
        [DEFAULT]: 'Ending On'
    },
    resendDocName: {
        [DEFAULT]: 'lease'
    },
    signatoryName: {
        [DEFAULT]: 'lessee'
    },
    signatoryNamePlural: {
        [DEFAULT]: 'lessees'
    },
    resendLeaseType: {
        [DEFAULT]: 'Commercial Lease'
    },
    otherExpenses: {
        [DEFAULT]: 'Other expenses and charges.'
    },
    service: {
        [DEFAULT]: 'Service'
    },
    estimate: {
        [DEFAULT]: 'Estimate'
    },
    dueDate: {
        [DEFAULT]: 'Due date'
    },
    rebateAmount: {
        [DEFAULT]: 'Amount'
    }
};

export const documentLabels = {
    tenant: {
        [DEFAULT]: 'Client'
    },
    tenantLower: {
        [DEFAULT]: 'client'
    },
    createLease: {
        [DEFAULT]: 'Create new agreement'
    },
    terminationNoticeDateNotice: {
        [QLD_STATE]:
            'When you calculate dates for notices, where the notice period is in days, weeks or months, you must not count the day the notice is given. If the time period allowed under the legislation for a party to do anything ends on a weekend or public holiday, then the end of the time period will be on the next business day.'
    }
};

export const renewalLabels = {
    createLease: {
        [DEFAULT]: 'Continue'
    }
};

export const generalAppLabels = {
    agencyDetails: {
        [AGENCY_ACCOUNT_TYPE]: 'Agency Details',
        [GENERAL_ACCOUNT_TYPE]: 'Office / Your details'
    },
    agent: {
        [AGENCY_ACCOUNT_TYPE]: 'Agent',
        [GENERAL_ACCOUNT_TYPE]: 'User'
    },
    agents: {
        [AGENCY_ACCOUNT_TYPE]: 'agents',
        [GENERAL_ACCOUNT_TYPE]: 'senders'
    },
    agencyLogo: {
        [AGENCY_ACCOUNT_TYPE]: 'Agency logo',
        [GENERAL_ACCOUNT_TYPE]: 'Office logo'
    },
    agencyName: {
        [AGENCY_ACCOUNT_TYPE]: 'Agency Name',
        [GENERAL_ACCOUNT_TYPE]: 'Trading Name'
    },
    agencyNameRequired: {
        [AGENCY_ACCOUNT_TYPE]: 'Agency Name *',
        [GENERAL_ACCOUNT_TYPE]: 'Trading Name *'
    },
    companyName: {
        [AGENCY_ACCOUNT_TYPE]: 'Company Name',
        [GENERAL_ACCOUNT_TYPE]: 'Company Name'
    },
    clientVisible: {
        [AGENCY_ACCOUNT_TYPE]: 'Tenant Visible Contact Number',
        [GENERAL_ACCOUNT_TYPE]: 'Client Visible Number'
    },
    agencyEmail: {
        [AGENCY_ACCOUNT_TYPE]: 'Agency email',
        [GENERAL_ACCOUNT_TYPE]: 'Email'
    },
    agencyEmailRequired: {
        [AGENCY_ACCOUNT_TYPE]: 'Agency email *',
        [GENERAL_ACCOUNT_TYPE]: 'Company email *'
    },
    create_account: {
        [AGENCY_ACCOUNT_TYPE]: 'Create Agency Account',
        [GENERAL_ACCOUNT_TYPE]: 'Create Account'
    },
    create_account_details: {
        [AGENCY_ACCOUNT_TYPE]: 'Create Account: Agency Details',
        [GENERAL_ACCOUNT_TYPE]: 'Create Account: Office Details'
    },
    agency_templates: {
        [AGENCY_ACCOUNT_TYPE]: 'Agency Templates',
        [GENERAL_ACCOUNT_TYPE]: 'Your Templates'
    }
};

export function getIntegrationLabel(selectedIntegration) {
    switch (selectedIntegration) {
        case integrationCompanies.INTEGRATION_PROPERTY_ME:
            return 'PropertyMe';
        case integrationCompanies.INTEGRATION_PROPERTY_TREE:
            return 'PropertyTree';
        case integrationCompanies.INTEGRATION_MANAGED:
            return 'Managed';
        case integrationCompanies.INTEGRATION_VAULT_RE:
            return 'VaultRE';
        case integrationCompanies.INTEGRATION_REAPIT_CONSOLE:
            return 'Console';
        default:
            return selectedIntegration;
    }
}
