import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaderTitle } from '../../../utils/formUtils';
import * as dashboard from '../../../actions/dashboard';
import { changeDocumentAgent } from '../../../actions/dashboard';
import { getTemplates } from '../../../actions/template';
import EditableAgentContent from '../../form/EditableAgentContent';
import { useDispatch, useSelector } from 'react-redux';
import SavingIndicator from '../components/SavingIndicator';
import { getIsRequestingTeam, getTeam, getAgencyIntegrations, getAgencyVaultReIntegration } from '../../../selectors/agency';
import { getUserInfo } from '../../../selectors/user';
import { getAllTeamMembers } from '../../../actions/agency';
import EditableDocumentTitle from '../../form/EditableDocumentTitle';
import EditableTemplateName from '../../form/EditableTemplateName';
import { addOrReplaceDocumentInList } from '../../../actions/document';
import BulkApprovalStatus from '../../../containers/dashboard/BulkApprovalStatus';
import EditSenderConfirmation from '../../modals/document/EditSenderConfirmation';
import { useAccountProvider } from '../../providers/AccountProvider';
import { DOCUMENT_CUSTOM } from '../../../config';
import { getAllPdfSenderDetails } from '../../../utils/documentUtils';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { UPLOAD_A_DOC_INTEGRATION } from '../../../constants/featureFlags';
import { isAgencyUserAccount } from '../../../utils/userUtils';
import { IntegrationConnectionV2 } from '../../integration/IntegrationConnectionV2';

export const DocumentHeaderLeft = ({
    doc,
    isTemplate,
    docType,
    isReadOnlyTemplate,
    saveDoc,
    integrationAddress,
    integration,
    linkIntegrationProperty,
    unLinkIntegrationProperty
}) => {
    const dispatch = useDispatch();

    const isUploadADocIntegrationEnabled = useFeatureFlag(UPLOAD_A_DOC_INTEGRATION);

    const accountType = useAccountProvider();
    const team = useSelector(getTeam);
    const agencyIntegrations = useSelector(getAgencyIntegrations);
    const vaultReIntegration = useSelector(getAgencyVaultReIntegration);

    const isRequestingTeam = useSelector(getIsRequestingTeam);
    const loggedUserInfo = useSelector(getUserInfo);
    const [agent, setAgent] = useState({
        value: doc.agent.id,
        label: doc.agent.fullName
    });

    const [senderDetailsOnPdf, setSenderDetailsOnPdf] = useState({
        name: '',
        totalAnnotationCount: 0
    });

    const [isLoading, setIsLoading] = useState(false);

    const [isEditSenderConfirmationModel, setIsEditSenderConfirmationModel] = useState(false);

    /**
     * Update agent when lease all info is grabbed
     */
    useEffect(() => {
        if (doc.agent && doc.agent.fullName) {
            setAgent({
                value: doc.agent.id,
                label: doc.agent.fullName
            });
        }

        if (doc && doc.uploadedDocuments) {
            const senderDetails = getAllPdfSenderDetails(doc.uploadedDocuments);
            setSenderDetailsOnPdf(senderDetails);
        }

        /**
         * If we dont have team yet, fetch team as it is used for the agent switch of an agreement
         */
        if (!isRequestingTeam && team.length === 0) {
            dispatch(getAllTeamMembers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doc]);

    const handleChangedAgent = (data, isEditPdf = false) => {
        setAgent(data.agent);
        setIsLoading(true);
        return axios.put(`/api/document/${doc.id}/agent`, { agent: data.agent.value }).then(() => {
            if (isTemplate) {
                dispatch(getTemplates(docType));
            } else {
                dispatch(changeDocumentAgent());
            }
            setIsLoading(false);
            setIsEditSenderConfirmationModel(false);
            if (isEditPdf) {
                saveDoc(isEditPdf);
            }
        });
    };

    /**
     * When changing the assigned agent on upload a doc, if pdf contains Agent/Sender name then popup confirmation message. otherwise user can change the assigned agent.
     */
    const changeOrOpenConfirmationModal = data => {
        if (
            doc.docType === DOCUMENT_CUSTOM &&
            doc.status === dashboard.LEASE_STATUS_DRAFT &&
            data.agent.value !== agent.value &&
            senderDetailsOnPdf.totalAnnotationCount > 0
        ) {
            setAgent(data.agent);
            setIsEditSenderConfirmationModel(true);
            return;
        }
        handleChangedAgent(data);
    };

    const closeEditSenderConfirmationModel = isEditPdf => {
        handleChangedAgent({ agent: agent }, isEditPdf);
    };

    const editSenderConfirmationHandler = () => {
        handleChangedAgent({ agent: agent });
    };
    const revertAgentChange = () => {
        setAgent({
            value: doc.agent.id,
            label: doc.agent.fullName
        });
        setIsEditSenderConfirmationModel(false);
    };

    const handleChangedDocumentTitle = data => {
        return axios.put(`/api/document/${doc.id}/document-title`, { documentTitle: data }).then(response => {
            if (isTemplate) {
                dispatch(getTemplates(docType));
            } else {
                dispatch(addOrReplaceDocumentInList(response.data.doc));
            }
        });
    };

    const saveTemplateName = newTemplateName => {
        return axios.put(`/api/template/${doc.id}/templateName`, { templateName: newTemplateName }).then(() => {
            dispatch(getTemplates(docType));
        });
    };

    const headerTitle = getHeaderTitle(doc.docType);

    function getStatus() {
        switch (doc.status) {
            case dashboard.LEASE_STATUS_DRAFT:
                return <span className="status draft">draft</span>;
            case dashboard.LEASE_STATUS_SENT_SIGNING:
                return <span className="status new">sent for signing</span>;
            case dashboard.LEASE_STATUS_AWAITING_COMPLETION:
                return <span className="status completed">awaiting completion</span>;
            case dashboard.LEASE_STATUS_COMPLETE: {
                return <span className="status archived">complete</span>;
            }
        }
    }

    return (
        <div className="header-left">
            <div className="header-left-wrapper">
                <div>
                    <div className="header-title">
                        <h1>{headerTitle}</h1>
                        <div className="status-wrapper">{getStatus()}</div>
                        <BulkApprovalStatus doc={doc} />
                        <SavingIndicator />
                    </div>
                    <React.Fragment>
                        {isTemplate && (
                            <div className="editable">
                                <EditableTemplateName
                                    templateName={doc.templateName}
                                    saveContent={saveTemplateName}
                                    disabled={isReadOnlyTemplate}
                                />
                            </div>
                        )}
                        {doc.status === dashboard.LEASE_STATUS_DRAFT && (
                            <div className="editable">
                                <EditableDocumentTitle
                                    originalDocumentTitle={doc.documentTitle}
                                    saveContent={handleChangedDocumentTitle}
                                    disabled={isReadOnlyTemplate}
                                />
                            </div>
                        )}
                        {doc.status !== dashboard.LEASE_STATUS_DRAFT && (
                            <div className="header-container address-info for-lg-modal">
                                <label>
                                    <p>
                                        <b>Title: </b>
                                    </p>
                                </label>
                                <p>{doc.documentTitle}</p>
                            </div>
                        )}
                        <div className="editable">
                            {agent.value && (
                                <>
                                    <EditableAgentContent
                                        type="input"
                                        agent={agent}
                                        paramName="agent"
                                        editContent={changeOrOpenConfirmationModal}
                                        disabled={isReadOnlyTemplate}
                                        doc={doc}
                                    />
                                    <EditSenderConfirmation
                                        isOpen={isEditSenderConfirmationModel}
                                        close={closeEditSenderConfirmationModel}
                                        primaryHandler={editSenderConfirmationHandler}
                                        secondaryHandler={closeEditSenderConfirmationModel}
                                        revertHandler={revertAgentChange}
                                        currentAgentName={senderDetailsOnPdf.name}
                                        accountType={accountType}
                                        loggedUserInfo={loggedUserInfo}
                                        isLoading={isLoading}
                                    />
                                </>
                            )}
                        </div>
                        {isUploadADocIntegrationEnabled &&
                            isAgencyUserAccount(accountType) &&
                            (agencyIntegrations?.activeIntegration || vaultReIntegration) &&
                            doc.docType === DOCUMENT_CUSTOM && (
                                <IntegrationConnectionV2
                                    integration={{
                                        address: integrationAddress,
                                        type: integration || agencyIntegrations.activeIntegration
                                    }}
                                    agencyIntegrations={agencyIntegrations}
                                    linkIntegrationProperty={linkIntegrationProperty}
                                    unLinkIntegrationProperty={unLinkIntegrationProperty}
                                    doc={doc}
                                />
                            )}
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
};

DocumentHeaderLeft.propTypes = {
    doc: PropTypes.object.isRequired
};
