import Panel from '@app/common/components/Panel.js';
import PremiseServices from '@app/components/lease/mainScreen/common/additionalTerms/PremiseServices.js';
import SeparatedWaterUsage from '@app/components/lease/mainScreen/common/additionalTerms/SeparatedWaterUsage.js';
import { mapUtilityServices } from '@app/hooks/helpers.js';
import '@app/sass/additional.scss';
import { getAgency } from '@app/selectors/settings/account.js';
import { getAgencyId } from '@app/selectors/user.js';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import cx from 'classnames';
import arrayMutators from 'final-form-arrays';
import { has, isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import SaveButtonIcon from '../SaveButtonIcon';
import styles from './WaterElectricityGasForm.module.scss';

type UtilityAgency = {
    defaultUtilityServices?: {
        separatedWaterUsage: string | null;
        premiseServices: {
            premiseServices: {
                name: string;
                mustPay: string | null;
                individuallyMetred: string | null;
                costApportionment: string;
                directToSupplier: string | null;
                howToPay: string;
                locked: true;
            }[];
        };
    };
};

const initialDefaultUtilityServices: UtilityAgency['defaultUtilityServices'] = {
    separatedWaterUsage: null,
    premiseServices: {
        premiseServices: [
            {
                name: 'Electricity',
                mustPay: null,
                individuallyMetred: null,
                costApportionment: '',
                directToSupplier: null,
                howToPay: '',
                locked: true
            },
            {
                name: 'Gas',
                mustPay: null,
                individuallyMetred: null,
                costApportionment: '',
                directToSupplier: null,
                howToPay: '',
                locked: true
            },
            {
                name: 'Phone',
                mustPay: null,
                individuallyMetred: null,
                costApportionment: '',
                directToSupplier: null,
                howToPay: '',
                locked: true
            }
        ]
    }
};

const WaterElectricityGasForm: React.FC = () => {
    const [defaultUtilityServices, setDefaultUtilityServices] =
        useState<UtilityAgency['defaultUtilityServices']>(initialDefaultUtilityServices);
    const defaultUtilityServicesRef = useRef<UtilityAgency['defaultUtilityServices']>();
    const agency: UtilityAgency = useSelector(getAgency);
    const agencyId = useSelector(getAgencyId);

    useEffect(() => {
        const mappedDefaultUtilityServices = mapUtilityServices(agency?.defaultUtilityServices);

        if (
            mappedDefaultUtilityServices === undefined ||
            isEqual(defaultUtilityServicesRef.current, agency?.defaultUtilityServices)
        ) {
            return;
        }

        setDefaultUtilityServices(mappedDefaultUtilityServices);
        defaultUtilityServicesRef.current = agency?.defaultUtilityServices;
    }, [agency, agency?.defaultUtilityServices]);

    const saveDefaultUtilityServices = useMutation<AxiosResponse, Error, UtilityAgency['defaultUtilityServices']>(
        data => {
            return axios.put(`/api/agency/${agencyId}/default-utility-services`, data);
        }
    );

    const submitForm = async (values: UtilityAgency['defaultUtilityServices']) => {
        // Reset mutate data
        // Otherwise it will keep old data and display old error/success messages
        saveDefaultUtilityServices.reset();

        try {
            await saveDefaultUtilityServices.mutateAsync(values);
        } catch (error) {
            let err = {};
            if (has(error, 'response.data.errors.defaultUtilityServices')) {
                err = error.response.data.errors.defaultUtilityServices;
            }
            return err;
        }
    };

    function addAdditionalService(push: (...args: unknown[]) => unknown) {
        const serviceDefault = {
            name: '',
            mustPay: null,
            individuallyMetred: null,
            costApportionment: '',
            directToSupplier: null,
            howToPay: '',
            locked: false
        };
        push('premiseServices.premiseServices', serviceDefault);
    }

    return (
        <div className={cx('additional', styles.additional)}>
            <Form
                onSubmit={submitForm}
                initialValues={defaultUtilityServices}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} id="waterElectricityGasForm">
                            <Panel
                                title="RTA DEFAULTS - SERVICES (QLD ONLY)"
                                rightButton={
                                    <div>
                                        <SaveButtonIcon
                                            isSaving={saveDefaultUtilityServices.isLoading}
                                            success={saveDefaultUtilityServices.isSuccess}
                                            failure={saveDefaultUtilityServices.isError}
                                        />
                                    </div>
                                }
                            >
                                <h4 className={styles.waterTitle}>Water</h4>
                                <SeparatedWaterUsage />

                                <PremiseServices
                                    addAdditionalService={addAdditionalService}
                                    additional={defaultUtilityServices}
                                    push={push}
                                />
                            </Panel>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
};

export default WaterElectricityGasForm;
