import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import appHistory from '../../../AppHistory';
import { ArrowBackIosSharp } from '@flk-mui-icons';
import { clearAddressDetailsStore, openAddressDetailsModal } from '../../../actions/addressDetails';
import Button from '../../../common/components/Button';
import { QLD_STATE, SA_STATE, VIC_STATE } from '../../../config';
import { nswIcon, qldIcon } from '../../../common/components/SVGIcons';
import { SelectField } from '../../form/FormSelect';
import FormAddressDetailsAutocomplete from '../../form/FormAddressDetailsAutocomplete';
import { getAddressDetails } from '../../../selectors/lease/addresses';
import { getAllowedTemplateList } from '../../../selectors/dashboard';
import { getCreateNewModalOpenedFromWelcome } from '../../../selectors/lease';

const getIconForLocation = location => {
    return location === QLD_STATE ? qldIcon : nswIcon;
};

function MobileView({
    closeLeaseAgreementForm,
    submitForm,
    formData,
    changeForm,
    enableChangeState,
    setEnableChangeState,
    enableChangeTemplate,
    setEnableChangeTemplate,
    selectUseTemplate,
    enableChangeAddress,
    addressSet,
    setEnableChangeAddress,
    cloneLeaseId,
    handleAddressChange,
    setAddressDetails,
    selectState
}) {
    const dispatch = useDispatch();

    const allowedTemplateList = useSelector(getAllowedTemplateList);
    const addressDetails = useSelector(getAddressDetails);
    const createNewModalOpenedFromWelcome = useSelector(getCreateNewModalOpenedFromWelcome);

    const addressValue = formData.address;
    const selectedTemplate = allowedTemplateList.find(element => element.value === formData.template);

    return (
        <div className="create-wrapper for-sm-modal">
            <div className="modal-header">
                <Button
                    className="back-btn"
                    onClick={() => {
                        closeLeaseAgreementForm();
                        if (createNewModalOpenedFromWelcome) {
                            appHistory.push('/welcome');
                        }
                    }}
                >
                    <ArrowBackIosSharp />
                </Button>
                <h6>Property Management Agreement</h6>
                <h2>Create new agreement</h2>
            </div>
            <Form onSubmit={values => submitForm(values)} initialValues={formData}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form className="full-width" onSubmit={handleSubmit} noValidate>
                            <FormSpy onChange={state => changeForm(state)} />
                            <div className="modal-body scrollable">
                                <div
                                    className={`create-section address-selector-wrapper ${
                                        !enableChangeState && values.location ? 'create-section--selected' : ''
                                    }`}
                                >
                                    <div className="create-section__selected-tab">
                                        <label>{values.location} Property Management Agreement</label>
                                        <Button
                                            className="text-only"
                                            type="button"
                                            onClick={() => {
                                                setEnableChangeState(true);
                                            }}
                                        >
                                            Change
                                        </Button>
                                    </div>
                                    <div className="create-section__selecting-slot create state-selector">
                                        <div className="create__body">
                                            {[QLD_STATE, SA_STATE, VIC_STATE].map((_state, index) => (
                                                <div
                                                    key={index}
                                                    className={`state-selector__item ${
                                                        values.location === _state ? 'state-selector__item--active' : ''
                                                    }`}
                                                    onClick={() => {
                                                        selectState(_state);
                                                    }}
                                                >
                                                    <div className="state-selector__item__inner">
                                                        <div className="state-selector__image">
                                                            {getIconForLocation(_state)}
                                                        </div>
                                                        <div className="state-selector__label">
                                                            <label>{_state}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`create-section address-selector-wrapper ${
                                        !enableChangeTemplate ? 'create-section--selected' : ''
                                    }`}
                                >
                                    <div className="create-section__selected-tab">
                                        <label>
                                            {values.template && selectedTemplate
                                                ? selectedTemplate.label
                                                : 'No Template'}
                                        </label>
                                        <Button
                                            className="text-only"
                                            type="button"
                                            onClick={() => {
                                                setEnableChangeTemplate(true);
                                            }}
                                        >
                                            Change
                                        </Button>
                                    </div>
                                    <div className="create-section__selecting-slot create template-selector">
                                        <div className="create__title">
                                            <h2>Do you want to use a template?</h2>
                                        </div>
                                        <div className="create__body">
                                            <div className="create__field-row">
                                                <Button
                                                    className={`outlined mobile radius-6 ${
                                                        values.isUseTemplate === false ? 'selected' : ''
                                                    }`}
                                                    onClick={() => {
                                                        selectUseTemplate(false);
                                                        enableChangeTemplate(false);
                                                    }}
                                                    type="button"
                                                >
                                                    No
                                                </Button>
                                                <Button
                                                    className={`outlined mobile radius-6 ${
                                                        values.isUseTemplate ? 'selected' : ''
                                                    }`}
                                                    onClick={() => {
                                                        selectUseTemplate(true);
                                                        enableChangeTemplate(true);
                                                    }}
                                                    type="button"
                                                >
                                                    Yes
                                                </Button>
                                            </div>
                                            {values.isUseTemplate && (
                                                <div className="create__field-row">
                                                    <SelectField
                                                        key={values.location}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        name="template"
                                                        options={allowedTemplateList}
                                                        onChange={value => {
                                                            if (value) {
                                                                enableChangeTemplate(false);
                                                            }
                                                        }}
                                                        selectClassName="sel-4"
                                                        placeholder="Select template"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`create-section address-selector-wrapper ${
                                        !enableChangeAddress && values.address && addressSet
                                            ? 'create-section--selected'
                                            : ''
                                    }`}
                                >
                                    <div className="create-section__selected-tab">
                                        <label>{values.address}</label>
                                        <Button
                                            className="text-only"
                                            type="button"
                                            onClick={() => {
                                                setEnableChangeAddress(true);
                                            }}
                                        >
                                            Change
                                        </Button>
                                    </div>
                                    <div className="create-section__selecting-slot create address-selector">
                                        <div className="create__title">
                                            <h2>What is the address of the property?</h2>
                                        </div>
                                        <div className="create__body">
                                            <div className="create__field-row">
                                                <span className="address-label">Address</span>
                                                <FormAddressDetailsAutocomplete
                                                    name="address"
                                                    isClone={!!cloneLeaseId}
                                                    value={addressValue}
                                                    editAddressFormData={addressDetails.editAddressFormData}
                                                    editedNewAddress={addressDetails.selectedAddressEdited}
                                                    editMode={addressDetails.editMode}
                                                    showAddressBelow={false}
                                                    detailsConfirmed={addressDetails.detailsConfirmed}
                                                    label="Address"
                                                    onChange={address => handleAddressChange(form.change, address)}
                                                    setAddressDetails={(address, details) =>
                                                        setAddressDetails(address, details)
                                                    }
                                                    openAddressDetailsModal={data =>
                                                        dispatch(openAddressDetailsModal(data))
                                                    }
                                                    clearAddressDetailsStore={() =>
                                                        dispatch(clearAddressDetailsStore())
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-btn-row fields-completed">
                                {values.location &&
                                    values.address &&
                                    addressSet &&
                                    (values.isUseTemplate === false || values.template) && (
                                        <Button className="mobile filled radius" type="submit">
                                            Create agreement
                                        </Button>
                                    )}
                                <Button
                                    className="mobile text-only"
                                    type="button"
                                    onClick={() => {
                                        closeLeaseAgreementForm();
                                        if (createNewModalOpenedFromWelcome) {
                                            appHistory.push('/welcome');
                                        }
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default MobileView;
