import { INTEGRATION_LABEL, UploadStatus } from '@app/constants/constants';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { uploadDocumentIntegration } from '../../../actions/dashboard.js';

export type AgreementIntegrationType = {
    integration: {
        id: string;
        address: string;
        type: keyof typeof INTEGRATION_LABEL;
        active: boolean;
        lastSuccessfulUpload: string;
        uploadStatus: UploadStatus;
    };
};

type AgreementIdType = { agreementId: string };

const useIntegrationUploadDocuments = () => {
    const dispatch = useDispatch();

    const getUploadStatus = ({ integration }: AgreementIntegrationType) => {
        return integration?.uploadStatus ?? UploadStatus.DEFAULT;
    };

    const integrationUploadDocumentsMutation = useMutation({
        mutationFn: async ({ agreementId }: AgreementIdType) => {
            if (!agreementId) {
                throw new Error(
                    'Required parameters are missing or invalid. Please check the upload document request.'
                );
            }

            const response = await axios.post(`/api/agency/lease/${agreementId}/integration-upload`);

            return response.data;
        }
    });

    const handleUploadClick = ({ agreementId }: AgreementIdType) => {
        if (agreementId) {
            dispatch(uploadDocumentIntegration(agreementId));
            integrationUploadDocumentsMutation.mutate({ agreementId });
        }
    };

    return { getUploadStatus, handleUploadClick };
};

export default useIntegrationUploadDocuments;
