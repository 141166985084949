import '../../sass/lightTooltip.scss';
import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openConnectIntegrationModal } from '../../actions/dashboard';
import { updateIntegration } from '../../actions/lease';
import '../../sass/leaseIntegration.scss';
import * as PropTypes from 'prop-types';
import { IntegrationConnect } from './IntegrationConnect';
import { IntegrationDisconnect } from './IntegrationDisconnect';
import { IntegrationPlaceholder } from './IntegrationPlaceholder';
import ConnectIntegrationModal from '../modals/ConnectIntegrationModal';
import { getIntegrationLabel } from '../../utils/labelUtils';

export const IntegrationConnection = ({
    integration,
    agencyIntegrations,
    lease,
    isDocument,
    viewOnly = false,
    doc,
    linkIntegrationProperty,
    unLinkIntegrationProperty,
    isKey,
    currentKey
}) => {
    const [error, setError] = useState();

    const dispatch = useDispatch();

    const openIntegrationModal = useCallback(() => dispatch(openConnectIntegrationModal()), [dispatch]);

    const unlink = useCallback(() => {
        if (isDocument && doc) {
            axios
                .delete(`/api/document/${doc.id}/integration`)
                .then(() => {
                    unLinkIntegrationProperty();
                })
                .catch(() => {
                    setError('Something went wrong, please try again.');
                });
        } else if (isDocument && !doc) {
            unLinkIntegrationProperty();
        } else if (isKey && currentKey) {
            axios
                .delete(`/api/flk-a-key/${currentKey.id}/integration`)
                .then(() => {
                    unLinkIntegrationProperty();
                })
                .catch(() => {
                    setError('Something went wrong, please try again.');
                });
        } else if (lease) {
            axios
                .delete(`/api/agency/lease/${lease.id}/integration`)
                .then(() => {
                    dispatch(updateIntegration({}));
                })
                .catch(() => {
                    setError('Something went wrong, please try again.');
                });
        }
    }, [dispatch, doc, isDocument, lease, unLinkIntegrationProperty, isKey, currentKey]);

    if (integration && typeof integration.property === 'string' && !isDocument && !isKey) {
        return <IntegrationPlaceholder label={getIntegrationLabel(agencyIntegrations.activeIntegration)} />;
    }

    const propertyIsConnected =
        integration && ((integration.property && typeof integration.property === 'object') || integration.address);
    const agencyIsConnected = agencyIntegrations && agencyIntegrations.activeIntegration;

    let address =
        integration && integration.property
            ? integration.property.addressText || integration.property.AddressText
            : false;
    if (!address && integration) {
        address = integration.address;
        if (!address && isKey) {
            address = currentKey.address;
        }
    }
    return (
        <React.Fragment>
            {agencyIsConnected && (
                <div className="integration-container for-lg-modal">
                    {propertyIsConnected && (
                        <IntegrationDisconnect
                            label={getIntegrationLabel(integration.type)}
                            propertyAddress={address}
                            onUnlink={unlink}
                            viewOnly={viewOnly}
                        />
                    )}
                    {!propertyIsConnected && (
                        <IntegrationConnect
                            label={getIntegrationLabel(agencyIntegrations.activeIntegration)}
                            onLink={openIntegrationModal}
                            viewOnly={viewOnly}
                        />
                    )}

                    {error && (
                        <div className="error-message">
                            <p>{error}</p>
                        </div>
                    )}
                </div>
            )}
            <ConnectIntegrationModal
                linkIntegrationProperty={linkIntegrationProperty}
                isDocument={isDocument}
                doc={doc}
                isKey={isKey}
                currentKey={currentKey}
            />
        </React.Fragment>
    );
};

IntegrationConnection.propTypes = {
    integration: PropTypes.object,
    agencyIntegrations: PropTypes.object,
    lease: PropTypes.object,
    isDocument: PropTypes.bool
};
