import { useMemo } from 'react';
import { get, isArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { removeSuggestion } from '../actions/lease';
import { getSuggestions } from '../selectors/lease';
import { SUGGESTIONS } from '../constants/featureFlags';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { 
    getAgencyPropertyMeIntegration, 
    getAgencyPropertyTreeIntegration, 
    getAgencyReapitConsoleIntegration 
} from '../selectors/agency.js';
import { Icons } from '../common/components/Icon';

interface Suggestion {
    isSuggestionsActive: boolean;
    suggestionsList: string[];
    suggestionIcon: Icons;
    removeSuggestionFromRedux: (index: number) => void;
}

const useSuggestions = (suggestionsFieldName: string): Suggestion => {
    const dispatch = useDispatch();
    const isSuggestionsActive = useFeatureFlag(SUGGESTIONS);
    const suggestions = useSelector(getSuggestions);
    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);
    const reapitConsoleIntegration = useSelector(getAgencyReapitConsoleIntegration);

    let suggestionsList: unknown = [];
    const suggestionValue = get(suggestions, suggestionsFieldName);

    if (suggestionValue) {
        suggestionsList = isArray(suggestionValue) ? suggestionValue : [suggestionValue];
    }

    const suggestionIcon: Icons = useMemo(() => {
        if (propertyMeIntegration) {
            return Icons.PROPERTY_ME;
        } else if (propertyTreeIntegration) {
            return Icons.PROPERTY_TREE;
        } else if (reapitConsoleIntegration) {
            return Icons.REAPIT_CONSOLE_LOGO;
        }
        return Icons.PLUS
    }, [propertyMeIntegration, propertyTreeIntegration]);

    function removeSuggestionFromRedux(index: number) {
        dispatch(removeSuggestion(suggestionsFieldName, index));
    }

    return {
        isSuggestionsActive,
        suggestionsList,
        suggestionIcon,
        removeSuggestionFromRedux
    };
};

export default useSuggestions;
