import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { NO_LABEL, NO_VALUE, YES_LABEL, YES_VALUE } from '../../../../config';

import AllowAnimals from '../../../../components/lease/mainScreen/common/additionalTerms/AllowAnimals';
import CommunityScheme from '../../../../components/lease/mainScreen/common/additionalTerms/CommunityScheme';
import DefenceClause from '../../../../components/lease/mainScreen/common/additionalTerms/DefenceClause';
import LeaseRenewal from '../../../../components/lease/mainScreen/common/additionalTerms/LeaseRenewal';
import PremiseServices from '../../../../components/lease/mainScreen/common/additionalTerms/PremiseServices';
import SeparatedWaterUsage from '../../../../components/lease/mainScreen/common/additionalTerms/SeparatedWaterUsage';
import SwimmingPool from '../../../../components/lease/mainScreen/common/additionalTerms/SwimmingPool';

import { DEFAULT_UTILITY_SERVICES } from '@app/constants/featureFlags';
import { mapUtilityServices } from '@app/hooks/helpers';
import { getAgencyInfo } from '@app/selectors/agency';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { useImmer } from 'use-immer';

const QldAdditional = ({
    values,
    addPet,
    allowPets,
    clearPets,
    addAdditionalService,
    push,
    additional,
    setAdditional,
    location,
    loggedInUser
}) => {
    const [defaultUtilityServices, setDefaultUtilityServices] = useState();
    const agencyInfo = useSelector(getAgencyInfo);
    const isDefaultUtilityServicesActive = useFeatureFlag(DEFAULT_UTILITY_SERVICES);

    useEffect(() => {
        if (
            !isDefaultUtilityServicesActive ||
            agencyInfo?.defaultUtilityServices === undefined ||
            defaultUtilityServices !== undefined
        ) {
            return;
        }

        const mappedDefaultUtilityServices = mapUtilityServices(agencyInfo?.defaultUtilityServices);

        setDefaultUtilityServices(mappedDefaultUtilityServices);

        // Check if mustPay and separatedWaterUsage are null
        // Indicating the initial state of premiseServices in the backend
        const initialBackendStateOfAdditionalTerms =
            additional?.premiseServices?.premiseServices?.every(service => service.mustPay == null) &&
            additional?.separatedWaterUsage == null;

        if (mappedDefaultUtilityServices && initialBackendStateOfAdditionalTerms) {
            setAdditional(draft => {
                Object.assign(draft, mappedDefaultUtilityServices);
            });
        }
    }, [
        defaultUtilityServices,
        agencyInfo?.defaultUtilityServices,
        isDefaultUtilityServicesActive,
        location,
        additional?.premiseServices?.premiseServices,
        additional?.separatedWaterUsage,
        setAdditional
    ]);

    return (
        <React.Fragment>
            {values.communityScheme !== null && <CommunityScheme />}
            {values.communityScheme === YES_VALUE && values.tenantReceivedCommunityScheme !== null && (
                <div className="additional-group">
                    <div className="formBox-column">
                        <FormRadioGroup
                            label="Has the tenant been given a copy of the relevant by-laws?"
                            name={'tenantReceivedCommunityScheme'}
                            className="additional-term-item"
                            data={[
                                {
                                    label: YES_LABEL,
                                    className: 'yes-confirm-yes',
                                    id: 'yes-confirm-yes',
                                    value: YES_VALUE
                                },
                                {
                                    label: NO_LABEL,
                                    className: 'yes-confirm-no',
                                    id: 'yes-confirm-no',
                                    value: NO_VALUE
                                }
                            ]}
                        />
                    </div>
                </div>
            )}
            <AllowAnimals
                values={values}
                allowPets={allowPets}
                clearPets={clearPets}
                addPet={addPet}
                push={push}
                additional={additional}
                location={location}
            />
            <SwimmingPool values={values} hasSwimingPoolMaintenance={false} location={location} />
            <DefenceClause />
            <SeparatedWaterUsage />
            {values.isLeaseRenewalEnabled !== null && <LeaseRenewal values={values} loggedInUser={loggedInUser} />}
            {additional.premiseServices && (
                <PremiseServices addAdditionalService={addAdditionalService} additional={additional} push={push} />
            )}
        </React.Fragment>
    );
};

export default memo(QldAdditional);
