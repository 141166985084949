import { FormApi } from 'final-form';
import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { IconSize } from '../../../../../common/components/Icon';
import TooltipIcon, { TooltipIconType } from '../../../../../common/components/TooltipIcon';
import {
    getAgencyManagedIntegration,
    getAgencyPropertyMeIntegration,
    getAgencyPropertyTreeIntegration,
    getAgencyVaultReIntegration
} from '../../../../../selectors/agency.js';
import { User } from '../../../../../types/User';
import EnableIntegrationSection from './EnableIntegrationSection';
import { integration, integrationType } from '@app/constants/constants';

import styles from './IntegrationAddressSection.module.scss';

type IntegrationAddressSectionProps = {
    loggedInUser: User;
    integrationError?: string;
    setIntegrationError: (error?: string) => void;
};

type BaseIntegrationSearchResult = {
    integration: string;
};

type PropertyTreeIntegrationSearchResult = BaseIntegrationSearchResult & {
    id: string;
    addressText: string;
};

type ManagedIntegrationSearchResult = BaseIntegrationSearchResult & {
    id: string;
    addressText: string;
};

type PropertyMeIntegrationSearchResult = BaseIntegrationSearchResult & {
    Id: string;
    AddressText: string;
};

type VaultReIntegrationSearchResult = BaseIntegrationSearchResult & {
    id: string;
    addressText?: string;
    integrationType: string;
    fullName?: string;
    lifeId?: string;
    lifeType?: string;
    emails?: string[];
    phoneNumbers?: { number: string }[];
};

type IntegrationSearchResult =
    | PropertyMeIntegrationSearchResult
    | PropertyTreeIntegrationSearchResult
    | ManagedIntegrationSearchResult
    | VaultReIntegrationSearchResult;

const IntegrationAddressSection: React.FC<IntegrationAddressSectionProps> = ({
    loggedInUser,
    integrationError,
    setIntegrationError
}) => {
    const form = useForm();

    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);
    const managedIntegration = useSelector(getAgencyManagedIntegration);
    const vaultReIntegration = useSelector(getAgencyVaultReIntegration);

    const [enabledIntegration, setEnabledIntegration] = useState<{
        [key: string]: boolean;
    }>({
        propertyMe: false,
        propertyTree: false,
        managed: false,
        vaultRe: false
    });

    const enableIntegration = (integration: string, value: boolean) => {
        resetIntegration(form);
        setEnabledIntegration(v => ({
            ...Object.keys(v).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, v),
            [integration]: value
        }));
    };

    const addIntegrationDetailsToDocument = async (form: FormApi, selectedIntegration?: IntegrationSearchResult) => {
        try {
            if (selectedIntegration) {
                let addressOrName = '';
                let integrationPropertyId = '';
                if (selectedIntegration.integration === integration.INTEGRATION_VAULT_RE) {
                    selectedIntegration = selectedIntegration as VaultReIntegrationSearchResult;
                    addressOrName = selectedIntegration.addressText || selectedIntegration.fullName || '';
                    integrationPropertyId = selectedIntegration.id;
                } else if (selectedIntegration.integration === integration.INTEGRATION_PROPERTY_ME) {
                    selectedIntegration = selectedIntegration as PropertyMeIntegrationSearchResult;
                    addressOrName = selectedIntegration.AddressText;
                    integrationPropertyId = selectedIntegration.Id;
                } else if (selectedIntegration.integration === integration.INTEGRATION_PROPERTY_TREE) {
                    selectedIntegration = selectedIntegration as PropertyTreeIntegrationSearchResult;
                    addressOrName = selectedIntegration.addressText;
                    integrationPropertyId = selectedIntegration.id;
                } else if (selectedIntegration.integration === integration.INTEGRATION_MANAGED) {
                    selectedIntegration = selectedIntegration as ManagedIntegrationSearchResult;
                    addressOrName = selectedIntegration.addressText;
                    integrationPropertyId = selectedIntegration.id;
                }

                form.batch(() => {
                    form.change('address', addressOrName);
                    form.change('integrationPropertyId', integrationPropertyId);
                    form.change('integration', selectedIntegration?.integration);
                    if (!form.getState().values.documentTitle) {
                        form.change('documentTitle', addressOrName);
                    }

                    if (selectedIntegration?.integration === integration.INTEGRATION_VAULT_RE) {
                        selectedIntegration = selectedIntegration as VaultReIntegrationSearchResult;
                        form.change('integrationType', selectedIntegration.integrationType);
                        if (selectedIntegration.integrationType === integrationType.PROPERTY) {
                            form.change('vaultReLifeId', selectedIntegration.lifeId);
                            form.change('vaultReLifeType', selectedIntegration.lifeType);
                        } else if (selectedIntegration.integrationType === integrationType.CONTACT) {
                            form.change('clients[0].name', selectedIntegration.fullName);
                            form.change('clients[0].email', selectedIntegration.emails?.[0] || '');
                            form.change('clients[0].phone', selectedIntegration.phoneNumbers?.[0]?.number || '');
                        }
                    } else {
                        form.change('integrationType', integrationType.PROPERTY);
                    }
                });
            }
        } catch (error) {
            setIntegrationError('Unable to load integration details');
        }
    };

    const resetIntegration = (form: FormApi) => {
        setIntegrationError(undefined);
        const { address, documentTitle } = form.getState().values;
        form.batch(() => {
            if (documentTitle === address) {
                form.change('documentTitle', '');
            }
            form.change('address', undefined);
            form.change('integrationPropertyId', undefined);
            form.change('integration', undefined);
            form.change('integrationType', undefined);
            form.change('vaultReLifeId', undefined);
            form.change('vaultReLifeType', undefined);
            form.change('clients', []);
        });
    };

    return (
        <>
            {(propertyMeIntegration || propertyTreeIntegration || managedIntegration || vaultReIntegration) && (
                <div className={styles.addressSectionHeadingSection}>
                    <h3 className={styles.addressSectionHeading}>
                        Link this document to one of your enabled Integrations
                    </h3>
                    <TooltipIcon type={TooltipIconType.INFO} iconSize={IconSize.SMALL}>
                        <div className={styles.integrationTooltipBody}>
                            Enhance your workflow by linking this document to any of these integrations. Simply enter
                            the property's address, and after the document is completed it will be automatically pushed
                            into your CRM.
                        </div>
                    </TooltipIcon>
                </div>
            )}
            {propertyMeIntegration && (
                <EnableIntegrationSection
                    loggedInUser={loggedInUser}
                    enabled={enabledIntegration.propertyMe}
                    onChangeToggle={value => enableIntegration(integration.INTEGRATION_PROPERTY_ME, value)}
                    selectedIntegration={integration.INTEGRATION_PROPERTY_ME}
                    withIntegrationType={false}
                    onChange={() => resetIntegration(form)}
                    onSelect={(selectedIntegration: IntegrationSearchResult) => {
                        return addIntegrationDetailsToDocument(form, selectedIntegration);
                    }}
                />
            )}
            {propertyTreeIntegration && (
                <EnableIntegrationSection
                    loggedInUser={loggedInUser}
                    enabled={enabledIntegration.propertyTree}
                    onChangeToggle={value => enableIntegration(integration.INTEGRATION_PROPERTY_TREE, value)}
                    selectedIntegration={integration.INTEGRATION_PROPERTY_TREE}
                    withIntegrationType={false}
                    onChange={() => resetIntegration(form)}
                    onSelect={(selectedIntegration: IntegrationSearchResult) => {
                        return addIntegrationDetailsToDocument(form, selectedIntegration);
                    }}
                />
            )}
            {managedIntegration && (
                <EnableIntegrationSection
                    loggedInUser={loggedInUser}
                    enabled={enabledIntegration.managed}
                    onChangeToggle={value => enableIntegration(integration.INTEGRATION_MANAGED, value)}
                    selectedIntegration={integration.INTEGRATION_MANAGED}
                    withIntegrationType={false}
                    onChange={() => resetIntegration(form)}
                    onSelect={(selectedIntegration: IntegrationSearchResult) => {
                        return addIntegrationDetailsToDocument(form, selectedIntegration);
                    }}
                />
            )}
            {vaultReIntegration && (
                <EnableIntegrationSection
                    loggedInUser={loggedInUser}
                    enabled={enabledIntegration.vaultRe}
                    onChangeToggle={value => enableIntegration(integration.INTEGRATION_VAULT_RE, value)}
                    selectedIntegration={integration.INTEGRATION_VAULT_RE}
                    withIntegrationType={true}
                    onChange={() => resetIntegration(form)}
                    onSelect={(selectedIntegration: IntegrationSearchResult) => {
                        return addIntegrationDetailsToDocument(form, selectedIntegration);
                    }}
                    helpText="This is pulled from your existing Property Profiles and their respective lifes’ on VaultRE."
                />
            )}
            {integrationError && <div className={styles.integrationError}>{integrationError}</div>}
        </>
    );
};

export default IntegrationAddressSection;
