import React, { useState, memo, useCallback } from 'react';
import { getId } from '@app/utils/getId';
import { useSelector, useDispatch } from 'react-redux';
import ModalDialog from './../../common/components/ModalDialog';
import { closeConnectIntegrationModal } from '../../actions/dashboard';
import { getIntegrationLabel } from '../../utils/labelUtils';
import IntegrationAddressInput from '../lease/IntegrationAddressInput';
import Button from '../../common/components/Button';
import axios from 'axios';
import { updateIntegration } from '../../actions/lease';

import '../../sass/connectIntegrationModal.scss';
import { getLeaseInfo } from '../../selectors/lease';
import { getIsConnectIntegrationModalOpen } from '../../selectors/dashboard';
import { getAgencyIntegrations } from '../../selectors/agency';

const ConnectIntegrationModal = ({ linkIntegrationProperty, isDocument, doc, isKey, currentKey }) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(getIsConnectIntegrationModalOpen);
    const agencyIntegrations = useSelector(getAgencyIntegrations);
    const lease = useSelector(getLeaseInfo);
    const [isSaving, setIsSaving] = useState(false);
    const [property, setProperty] = useState(null);
    const [error, setError] = useState();

    const closeModal = useCallback(() => {
        dispatch(closeConnectIntegrationModal());
    }, [dispatch]);

    const handlePropertySelect = useCallback(
        property => {
            setProperty(property);
        },
        [setProperty]
    );

    const onLink = async () => {
        setIsSaving(true);

        const integrationData = {
            property: property._id,
            id: property.id || property.Id,
            type: property.integration,
            address: property.addressText || property.AddressText
        };

        try {
            if (isDocument) {
                if (doc) {
                    await axios.post(`/api/document/${doc.id}/integration`, integrationData);
                    await linkIntegrationProperty(integrationData);
                    closeModal();
                } else {
                    await linkIntegrationProperty(integrationData);
                    closeModal();
                }
            } else if (isKey) {
                if (currentKey) {
                    await axios.post(`/api/flk-a-key/${currentKey.id}/integration`, integrationData);
                    await linkIntegrationProperty(integrationData);
                    closeModal();
                } else {
                    await linkIntegrationProperty(integrationData);
                    closeModal();
                }
            } else {
                await axios.post(`/api/agency/lease/${lease.id}/integration`, integrationData);
                integrationData.active = true;
                integrationData.property = property;
                dispatch(updateIntegration(integrationData));
                closeModal();
            }
        } catch (error) {
            setError(
                'Error trying to add the connection to ' +
                    getIntegrationLabel(agencyIntegrations.activeIntegration) +
                    ', Please try again.'
            );
        } finally {
            setIsSaving(false);
        }
    };

    return (
        agencyIntegrations && (
            <ModalDialog
                title={'Link with ' + getIntegrationLabel(agencyIntegrations.activeIntegration)}
                isOpen={isOpen}
                closeModal={closeModal}
                className="connect-integration-modal"
                zIndex={7}
            >
                Type the address below and select an item from the dropdown to create a connection/link between this{' '}
                {isDocument ? 'document' : 'lease'} in FLK and {getIntegrationLabel(agencyIntegrations.activeIntegration)}.
                <IntegrationAddressInput name="address" label={'Address'} onSelect={handlePropertySelect} />
                <Button
                    primary
                    className="btn-terminate"
                    height={40}
                    type="button"
                    onClick={onLink}
                    disabled={isSaving || !property}
                >
                    {isSaving && <span className="savestatus-spinner-await-completion" />}
                    Save
                </Button>
                {error && (
                    <div className="error-message">
                        <br />
                        <p>{error}</p>
                    </div>
                )}
            </ModalDialog>
        )
    );
};

export default memo(ConnectIntegrationModal);
