import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getPmOtherServices } from '../../../selectors/lease/pmLease';

import { FormTextRegular } from '../../../components/form/FormText';
import { OtherServiceGroup } from '../../../components/lease/mainScreen/common/pm/OtherServiceGroup';
import { QLD_STATE, NSW_STATE } from '../../../config';
import plusIcon from "../../../../assets/images/icons/plus.svg?url";

const PM_OTHER_SERVICES_FORM = 'pmOtherServicesForm';
const initState = {
    additionalTenantSearches: '$0',
    titleSearch: '$0',
    inspectionOfPremises: '$0',
    arrangingForQuotes: '$0',
    arrangingRepairsOrMaintenance: '$0',
    negotiatingRentIncreases: '$0',
    applyForTribunal: '$0',
    preparationOfEvidenceForTheTribunal: '$0',
    appearingAtTribunal: '$0',
    applyingForWarrentOfPossession: '$0',
    sheriffsFees: '$0',
    obtainingCertifiedOrder: '$0',
    waterAndSewerage: '$0',
    processingInsuaranceClaims: '$0',
    preparingInventoryReport: '$0',
    serviceList: []
};

const OtherServices = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmOtherServices = useSelector(getPmOtherServices);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [pmOtherServices, setPmOtherServices] = useState(reduxPmOtherServices || initState);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_OTHER_SERVICES_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updatePmOtherServices(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmOtherServices')) {
                    return error.response.data.errors.pmOtherServices;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updatePmOtherServices = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/pm-other-services`, data);
    };

    const removeOtherService = (values, index) => {
        let newOtherServices = cloneDeep(values);

        newOtherServices.serviceList = [
            ...newOtherServices.serviceList.slice(0, index),
            ...newOtherServices.serviceList.slice(index + 1)
        ];
        setPmOtherServices(newOtherServices);
    };
    return (
        <div className="form-container">
            <Form
                onSubmit={submitForm}
                initialValues={pmOtherServices}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_OTHER_SERVICES_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div>
                                <h4>All fees listed below are inclusive of GST.</h4>
                                <FormTextRegular
                                    name="additionalTenantSearches"
                                    label="Additional Tenant Searches (ASIC/Bankruptcy/TICA/TRA)"
                                    required
                                />
                                {location === QLD_STATE && <FormTextRegular name="titleSearch" label="Title Search" />}
                                <FormTextRegular
                                    name="inspectionOfPremises"
                                    label="Inspection Of Premises/Report Fee"
                                    required
                                />
                                <FormTextRegular name="arrangingForQuotes" label="Arranging Quotes" required />
                                <FormTextRegular
                                    name="arrangingRepairsOrMaintenance"
                                    label="Arranging Repairs Or Maintenance"
                                    required
                                />
                                <FormTextRegular
                                    name="negotiatingRentIncreases"
                                    label="Negotiating Rent Increases"
                                    required
                                />
                                <FormTextRegular
                                    name="applyForTribunal"
                                    label="Applying For A Tribunal Order"
                                    required
                                />
                                <FormTextRegular
                                    name="preparationOfEvidenceForTheTribunal"
                                    label="Preparation Of Evidence For The Tribunal"
                                    required
                                />
                                <FormTextRegular
                                    name="appearingAtTribunal"
                                    label="Appearing At The Tribunal"
                                    required
                                />
                                <FormTextRegular
                                    name="applyingForWarrentOfPossession"
                                    label="Applying For A Warrant Of Possession"
                                    required
                                />
                                {location === NSW_STATE && (
                                    <FormTextRegular name="sheriffsFees" label="Sheriff's Fees" required />
                                )}
                                <FormTextRegular
                                    name="obtainingCertifiedOrder"
                                    label="Obtaining A Certified Order And Filling With The Local Court"
                                    required
                                />
                                <FormTextRegular
                                    name="waterAndSewerage"
                                    label="Calculation And Collection Of Water And Sewerage"
                                    required
                                />
                                <FormTextRegular
                                    name="processingInsuaranceClaims"
                                    label="Processing Insurance Claims"
                                    required
                                />
                                <FormTextRegular
                                    name="preparingInventoryReport"
                                    label="Preparing Inventory Report Where Premises Are Furnished"
                                    required
                                />
                            </div>
                            <FieldArray name="serviceList" initialValue={pmOtherServices.serviceList}>
                                {({ fields }) => (
                                    <React.Fragment>
                                        {fields.map((name, index) => {
                                            return (
                                                <OtherServiceGroup
                                                    index={index}
                                                    key={`${index}`}
                                                    removeOtherService={index => removeOtherService(values, index)}
                                                />
                                            );
                                        })}
                                    </React.Fragment>
                                )}
                            </FieldArray>
                            <div className="button">
                                <button
                                    className="mobile add-item"
                                    type="button"
                                    onClick={() =>
                                        push('serviceList', {
                                            serviceName: '',
                                            cost: '$'
                                        })
                                    }
                                >
                                    <img src={plusIcon} className="for-sm-modal" />
                                    Add a custom service
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(OtherServices);
