import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { subDays, parseISO, differenceInDays, getDay, isAfter, startOfDay, addDays } from 'date-fns';

import { getAgency, getUserInfo } from '../../../../../../selectors/user';
import {
    formatDateDay,
    getAgencyTimezoneDate,
    getAgencyTimezoneFromUser,
    isWeekday
} from '../../../../../../utils/dateUtils';
import { getLeaseInfo } from '../../../../../../selectors/lease';

import ModalDialog from '../../../../../../common/components/ModalDialog';
import DatePickerInAgencyTimeZone from '../../../../../form/DatePickerInAgencyTimeZone';
import Button from '../../../../../../common/components/Button';
import RenewalFlowSummary from './RenewalFlowSummary';

import { FormRadioGroupSummaryButton } from '../../../../../form/FormRadioGroupButton';

import { ReactComponent as ScheduleImage } from '../../../../../../../assets/images/schedule_image.svg';
import { ReactComponent as SendTogetherImage } from '../../../../../../../assets/images/send_together_image.svg';
import {
    SCHEDULE_TYPE_NOW,
    SCHEDULE_TYPE_LATER,
    DOCUMENT_TERMINATION_NOTICE,
    QLD_STATE
} from '../../../../../../config';
import IssueNoticeToLeaveSwitch from './components/IssueNoticeToLeaveSwitch';

import styles from './RenewalFlowModal.module.scss';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import Icon, { Icons, IconSize } from '../../../../../../common/components/Icon';
import { getLabel } from '../../../../../../utils/labelUtils';

function isNotInThePast(date) {
    return isAfter(startOfDay(date), new Date());
}

function VacantPossessionSuggestion({ values, lease, handleOnClick, loggedInUser }) {
    return (
        <>
            {!values.vacantPossessionDate && lease.term && isNotInThePast(parseISO(lease.term.startDate)) && (
                <>
                    <Button
                        onClick={handleOnClick}
                        type="button"
                        suggestion
                        data-tip
                        data-for="suggestedVacantPosessionDate"
                    >
                        {formatDateDay(
                            subDays(parseISO(lease.term.startDate), 1),
                            getAgencyTimezoneFromUser(loggedInUser)
                        )}
                    </Button>
                    <ReactTooltip id="suggestedVacantPosessionDate" effect="solid" globalEventOff="wheel">
                        Suggested date - This is the day before the lease start date
                    </ReactTooltip>
                </>
            )}
        </>
    );
}

function SendItTogetherFields({ form, values }) {
    const loggedInUser = useSelector(getUserInfo);
    const lease = useSelector(getLeaseInfo);

    return (
        <div className={styles.noticeToRenewal}>
            <div className={styles.formData}>
                <DatePickerInAgencyTimeZone
                    label="What is the vacant possession date?"
                    name="vacantPossessionDate"
                    selected={values.vacantPossessionDate}
                    minValue={getAgencyTimezoneDate(addDays(new Date(), 1), loggedInUser)}
                    popperPlacement="top-start"
                    newVersion
                    className={styles.smallMargin}
                    isClearable={true}
                />

                <VacantPossessionSuggestion
                    lease={lease}
                    values={values}
                    handleOnClick={() =>
                        form.change('vacantPossessionDate', subDays(parseISO(lease.term.startDate), 1))
                    }
                    loggedInUser={loggedInUser}
                />
            </div>
        </div>
    );
}

function ScheduleLaterFields({ values, setFormData }) {
    const [noticeToLeaveDateUpdated, setNoticeToLeaveDateUpdated] = useState(false);

    const agencySelected = useSelector(getAgency);
    const loggedInUser = useSelector(getUserInfo);
    const lease = useSelector(getLeaseInfo);

    function calculateDaysBeforeFields(values) {
        if (values.reminderEmailDate && values.noticeToLeaveDate) {
            values.daysBeforeReminderToSign = differenceInDays(values.noticeToLeaveDate, values.reminderEmailDate);
        } else {
            values.daysBeforeReminderToSign = 0;
        }
        if (values.noticeToLeaveDate && values.vacantPossessionDate) {
            values.daysBeforeNoticeToLeave = differenceInDays(values.vacantPossessionDate, values.noticeToLeaveDate);
        } else {
            values.daysBeforeNoticeToLeave = 0;
        }
        setFormData(values);
    }

    function changeVacantPossessionDate(values, value) {
        const data = cloneDeep(values);
        setNoticeToLeaveDateUpdated(false);
        data.vacantPossessionDate = value;
        // If there is default value then calculate other dates according to that
        if (value && agencySelected?.renewalNoticeToLeaveDetails?.daysBeforeNoticeToLeave) {
            let noticeToLeaveDate = subDays(
                value,
                agencySelected?.renewalNoticeToLeaveDetails?.daysBeforeNoticeToLeave
            );
            // only fill in if the date is not in the past
            data.daysBeforeNoticeToLeave = agencySelected.renewalNoticeToLeaveDetails.daysBeforeNoticeToLeave;
            // Check generated noticeToLeaveDate is in a saturday or sunday.
            // If so then change the date to previous friday
            const day = getDay(noticeToLeaveDate);
            // day === 0 means day is sunday and  6 means saturday
            if (day === 0 || day === 6) {
                const noOfDaysToFriday = day === 0 ? 2 : 1;
                // calculate new noticeToLeaveDate
                noticeToLeaveDate = subDays(noticeToLeaveDate, noOfDaysToFriday);
                setNoticeToLeaveDateUpdated(true);
                data.daysBeforeNoticeToLeave = data.daysBeforeNoticeToLeave + noOfDaysToFriday;
            }
            // only fill in if the date is not in the past
            if (isNotInThePast(noticeToLeaveDate)) {
                data.noticeToLeaveDate = noticeToLeaveDate;
            } else {
                data.noticeToLeaveDate = null;
                data.reminderEmailDate = null;
            }
            // If no default dates and already have other dates, then calculate daysBeforeNoticeToLeave
        } else if (value && data.noticeToLeaveDate) {
            data.daysBeforeNoticeToLeave = differenceInDays(value, data.noticeToLeaveDate);
        }

        // If there is default value then calculate other dates according to that
        if (data.noticeToLeaveDate && agencySelected?.renewalNoticeToLeaveDetails?.daysBeforeReminderToSign) {
            const reminderEmailDate = subDays(
                data.noticeToLeaveDate,
                agencySelected?.renewalNoticeToLeaveDetails?.daysBeforeReminderToSign
            );
            if (isNotInThePast(reminderEmailDate)) {
                data.reminderEmailDate = reminderEmailDate;
            } else {
                data.reminderEmailDate = null;
            }

            data.daysBeforeReminderToSign = agencySelected.renewalNoticeToLeaveDetails.daysBeforeReminderToSign;
            // If no default dates and already have other dates, then calculate daysBeforeReminderToSign
        } else if (data.noticeToLeaveDate && data.reminderEmailDate) {
            data.daysBeforeReminderToSign = differenceInDays(data.noticeToLeaveDate, data.reminderEmailDate);
        }
        setFormData(data);
    }

    return (
        <React.Fragment>
            <div className={styles.noticeToRenewal}>
                <div className={styles.formData}>
                    {values.vacantPossessionDate && (
                        <React.Fragment>
                            <DatePickerInAgencyTimeZone
                                label="When would you like to send the signing reminder?"
                                name="reminderEmailDate"
                                popperPlacement="top-start"
                                newVersion
                                minValue={getAgencyTimezoneDate(addDays(new Date(), 1), loggedInUser)}
                                className={styles.smallMargin}
                                isClearable={true}
                                selected={values.reminderEmailDate}
                                onChange={value => {
                                    const data = cloneDeep(values);
                                    data.reminderEmailDate = value;
                                    calculateDaysBeforeFields(data);
                                }}
                            />
                            <DatePickerInAgencyTimeZone
                                label="What date would you like to issue NTL (Form 12)?"
                                subLabel="*QLD legislation requires 2 months minimum"
                                name="noticeToLeaveDate"
                                popperPlacement="top-start"
                                newVersion
                                required
                                className={styles.smallMargin}
                                isClearable={true}
                                minValue={getAgencyTimezoneDate(addDays(new Date(), 1), loggedInUser)}
                                selected={values.noticeToLeaveDate}
                                onChange={value => {
                                    const data = cloneDeep(values);
                                    data.noticeToLeaveDate = value;
                                    calculateDaysBeforeFields(data);
                                    setNoticeToLeaveDateUpdated(false);
                                }}
                                filterDate={isWeekday}
                            />
                            {values.noticeToLeaveDate && noticeToLeaveDateUpdated && (
                                <p className={cx(styles.smallMargin, 'text-warning')}>
                                    <span className="icon icon-warning" /> Notice to Leave date will fall on a weekend.
                                    This date needs to be on weekday. The Notice to Leave date has been updated to the
                                    Friday prior to your chosen date.
                                </p>
                            )}
                        </React.Fragment>
                    )}
                    <DatePickerInAgencyTimeZone
                        label="What is the vacant possession date on the NTL (Form 12)?"
                        name="vacantPossessionDate"
                        selected={values.vacantPossessionDate}
                        required
                        minValue={getAgencyTimezoneDate(addDays(new Date(), 1), loggedInUser)}
                        onChange={value => {
                            changeVacantPossessionDate(values, value);
                        }}
                        popperPlacement="top-start"
                        newVersion
                        className={styles.smallMargin}
                        isClearable={true}
                        filterDate={isWeekday}
                        toolTipIcon={
                            <Icon
                                icon={Icons.INFO}
                                size={IconSize.SMALL}
                                data-for="terminationNoticeNoWeekendTooltip"
                                className="icon terminate-lease__info-icon"
                                data-tip={true}
                            />
                        }
                    />
                    <ReactTooltip
                        place="right"
                        effect="solid"
                        id="terminationNoticeNoWeekendTooltip"
                        globalEventOff="wheel"
                    >
                        <div>
                            <strong>Note</strong>:{' '}
                            {getLabel('terminationNoticeDateNotice', QLD_STATE, DOCUMENT_TERMINATION_NOTICE)}
                        </div>
                    </ReactTooltip>
                </div>
                <RenewalFlowSummary values={values} />
            </div>
            <VacantPossessionSuggestion
                lease={lease}
                values={values}
                handleOnClick={() => changeVacantPossessionDate(values, subDays(parseISO(lease.term.startDate), 1))}
                loggedInUser={loggedInUser}
            />
        </React.Fragment>
    );
}

function RenewalFlowModal({
    form,
    values,
    isOpen,
    changeNoticeToLeaveFlow,
    setFormData,
    closeModal,
    isLoading,
    disabled
}) {
    return (
        <ModalDialog
            isOpen={isOpen}
            closeModal={() => closeModal(form, values)}
            shouldCloseOnOverlayClick={false}
            className={styles.editRenewalModal}
            modalHeadClassName="empty"
            parentSelector="renewalNoticeToLeaveDetailsForm"
            dataTest="renewal-notice-to-leave-details-modal"
        >
            <IssueNoticeToLeaveSwitch
                changeNoticeToLeaveFlow={changeNoticeToLeaveFlow}
                disabled={disabled}
                showTooltip={true}
                values={values}
            />
            <FormRadioGroupSummaryButton
                className={styles.radioGroup}
                radioItemClassName={styles.radioOption}
                name="scheduleType"
                value={values.scheduleType}
                data={[
                    {
                        label: 'Schedule it',
                        message: 'Automatically send the Notice to Leave at a date.',
                        image: <ScheduleImage />,
                        value: SCHEDULE_TYPE_LATER,
                        dataTest: 'button-schedule-it'
                    },
                    {
                        label: 'Send it together',
                        message: 'Send both lease renewal and notice to leave at the same time',
                        image: <SendTogetherImage />,
                        value: SCHEDULE_TYPE_NOW,
                        dataTest: 'button-send-it-together'
                    }
                ]}
            />

            {values.scheduleType === SCHEDULE_TYPE_LATER && (
                <ScheduleLaterFields setFormData={setFormData} values={values} />
            )}
            {values.scheduleType === SCHEDULE_TYPE_NOW && <SendItTogetherFields form={form} values={values} />}
            <p className="text-gray">All Termination Notices will have reason 'End of term agreement has expired'</p>
            <div className={styles.renewalFooter}>
                <Button
                    type="submit"
                    secondary
                    newSecondary
                    className={styles.saveButton}
                    loading={isLoading}
                    data-test={'save-button'}
                >
                    Save
                </Button>
            </div>
        </ModalDialog>
    );
}

export default RenewalFlowModal;
