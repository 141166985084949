import { AvailableCountries } from '../constants/constants';
import { getCountryMobileNumberPrefix } from './countryUtils';

const AU_MAX_DIGITS = 11;
const NZ_MAX_DIGITS = 12;

export const formatPhoneNumber = (formattedValue: string, countryCode?: string): string => {
    const defaultCountryPrefix = getCountryMobileNumberPrefix(countryCode);
    const auPrefix = getCountryMobileNumberPrefix(AvailableCountries.AU);
    const nzPrefix = getCountryMobileNumberPrefix(AvailableCountries.NZ);

    let newFormattedValue = formattedValue;

    const formatPrefix = (value: string): string => {
        if (value[4] === '0') {
            if (countryCode === AvailableCountries.NZ) {
                return value.length === 5 ? value.substring(0, 4) : `${nzPrefix} ${value.substring(5)}`;
            } else {
                return value.length === 5 ? value.substring(0, 4) : `${auPrefix} ${value.substring(5)}`;
            }
        } else if (value.startsWith(`${auPrefix} ${auPrefix.slice(1)}`)) {
            return `${auPrefix}${value.substring(6)}`;
        } else if (value.startsWith(`${nzPrefix} ${nzPrefix.slice(1)}`)) {
            return `${nzPrefix}${value.substring(6)}`;
        }
        return value;
    };

    const limitDigits = (value: string, maxDigits: number): string => {
        const digitsOnly = value.replace(/[\D]/g, '');
        if (digitsOnly.length > maxDigits) {
            return `+${digitsOnly.slice(0, maxDigits)}`;
        } else {
            return value;
        }
    };

    const formatSpaces = (value: string, country: AvailableCountries): string => {
        // Different formatting for Australia and New Zealand
        if (country === AvailableCountries.AU) {
            return value
                .replace(/\s/g, '')
                .replace(/(.{3})(\d{3})(\d{3})(.*)/, '$1 $2 $3 $4') // Format as three groups of three
                .replace(/(.{3})(\d{3})(.*)/, '$1 $2 $3') // Handle inputs with less than 9 characters
                .replace(/(.{3})(.*)/, '$1 $2') // Handle inputs with less than 6 characters
                .replace(/ {2,}/g, ' ') // Replace multiple spaces with a single space
                .trim();
        } else if (country === AvailableCountries.NZ) {
            return value
                .replace(/\s/g, '')
                .replace(/(.{3})(\d{2})(\d{3})(.*)/, '$1 $2 $3 $4') // Format as three groups of [3,2,3,...]
                .replace(/(.{3})(\d{2})(.*)/, '$1 $2 $3') // Handle inputs with less than 9 characters
                .replace(/(.{3})(.*)/, '$1 $2') // Handle inputs with less than 6 characters
                .replace(/ {2,}/g, ' ') // Replace multiple spaces with a single space
                .trim();
        }
        return value;
    };

    if (formattedValue.startsWith(auPrefix)) {
        newFormattedValue = formatPrefix(formattedValue);
        newFormattedValue = limitDigits(newFormattedValue, AU_MAX_DIGITS);
    } else if (newFormattedValue.startsWith(nzPrefix)) {
        newFormattedValue = formatPrefix(formattedValue);
        newFormattedValue = limitDigits(newFormattedValue, NZ_MAX_DIGITS);
    } else if (formattedValue.startsWith('+0')) {
        newFormattedValue = `${defaultCountryPrefix}${newFormattedValue.substring(2)}`;
    } else if (formattedValue.startsWith('0')) {
        newFormattedValue = `${defaultCountryPrefix}${newFormattedValue.substring(1)}`;
    }

    if (newFormattedValue.startsWith(auPrefix)) {
        return formatSpaces(newFormattedValue, AvailableCountries.AU);
    }

    if (newFormattedValue.startsWith(nzPrefix)) {
        return formatSpaces(newFormattedValue, AvailableCountries.NZ);
    }

    return formattedValue === '+' ? '' : newFormattedValue;
};
