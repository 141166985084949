import Icon, { Icons } from '@app/common/components/Icon';
import { UploadStatus } from '@app/constants/constants';
import React from 'react';

type UploadStatusBlockProps = {
    uploadStatus: UploadStatus;
};

const UploadStatusBlock: React.FC<UploadStatusBlockProps> = ({ uploadStatus }) => {
    if (uploadStatus === UploadStatus.DEFAULT) {
        return null;
    }

    return (
        <>
            {uploadStatus === UploadStatus.SUCCESS && (
                <Icon className="upload-icon success" icon={Icons.NOTE_SUCCESS} />
            )}
            {uploadStatus === UploadStatus.FAILED && <Icon className="upload-icon error" icon={Icons.NOTE_ERROR} />}
            {uploadStatus === UploadStatus.UPLOADING && (
                <Icon className="upload-icon uploading" icon={Icons.ALERT_SYNC} />
            )}
        </>
    );
};

export default UploadStatusBlock;
