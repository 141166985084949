import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import ReactTooltip from 'react-tooltip';
import {
    NSW_STATE,
    QLD_STATE,
    VIC_STATE,
    ACT_STATE,
    YES_VALUE,
    NO_VALUE,
    ADDITIONAL_OPTION_OF_RENEWAL_CPI,
    REVIEW_METHOD_CPI,
    ADDITIONAL_OPTION_OF_RENEWAL_FIXED,
    REVIEW_METHOD_FIXED_PERCENTAGE,
    REVIEW_METHOD_FIXED_AMOUNT
} from '../../../config';
import { useImmer } from 'use-immer';
import '../../../sass/additional.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
    getLocation,
    getAdditionalTerms,
    getStep,
    getDirtyStep,
    getLeaseType,
    getRent
} from '../../../selectors/lease';
import arrayMutators from 'final-form-arrays';
import { setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import axios from 'axios';
import * as Lease from '../../../reducers/lease';
import { getUserInfo } from '../../../selectors/user';
import { cloneDeep, has } from 'lodash';

import NswAdditional from './NSW/Additional';
import QldAdditional from './QLD/Additional';
import VicAdditional from './VIC/Additional';
import ActAdditional from './ACT/Additional';
import { isLeaseTypeCommercialLease } from '../../../utils/agreementUtils';

const ADDITIONAL_FORM = 'additionalForm';

const Additional = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxAdditional = useSelector(getAdditionalTerms);
    const rent = useSelector(getRent);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const loggedInUser = useSelector(getUserInfo);

    let [additional, setAdditional] = useImmer({
        ...reduxAdditional,
        leaseRenewalDate:
            reduxAdditional.leaseRenewalDate && reduxAdditional.leaseRenewalDate !== ''
                ? new Date(reduxAdditional.leaseRenewalDate)
                : null,
        optionOfRenewal: getDefaultOptionOfRenewal()
    });
    const [bypassFormValidation, setBypassFormValidation] = useState(false);

    function getDefaultOptionOfRenewal() {
        if (reduxAdditional.optionOfRenewal) {
            return reduxAdditional.optionOfRenewal;
        } else if (rent.methodOfReview === REVIEW_METHOD_CPI) {
            return ADDITIONAL_OPTION_OF_RENEWAL_CPI;
        } else if ([REVIEW_METHOD_FIXED_AMOUNT, REVIEW_METHOD_FIXED_PERCENTAGE].includes(rent.methodOfReview)) {
            return ADDITIONAL_OPTION_OF_RENEWAL_FIXED;
        }
        return undefined;
    }

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            setBypassFormValidation(bypassFormValidation);
            document.getElementById(ADDITIONAL_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const addPet = push => {
        push('animals.pets', {
            information: '',
            amount: ''
        });
    };

    const allowPets = (push, values) => {
        if (values.allowAnimals === NO_VALUE) {
            push('animals.pets', {
                information: '',
                amount: ''
            });
        }
    };

    const setNoOwnerRights = values => {
        setAdditional(draft => {
            Object.assign(draft, values);
            draft.isNotTheOwner = YES_VALUE;
            draft.notTheOwnerRights =
                'The rental provider has exclusive leasing rights and the permission of the home owner to lease the property.';
        });
    };

    const clearPets = values => {
        setAdditional(draft => {
            Object.assign(draft, values);
            draft.allowAnimals = NO_VALUE;
            draft.animals.pets = [];
        });
    };

    const updateAdditional = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/additional-terms`, data);
    };

    const submitForm = values => {
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        const clonedValues = cloneDeep(values);
        clonedValues.bypassFormValidation = currentRef.bypassFormValidation;
        return updateAdditional(clonedValues)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_ADDITIONAL_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                return has(error, 'response.data.errors.additionalTerms')
                    ? error.response.data.errors.additionalTerms
                    : {};
            });
    };

    function addAdditionalService(push) {
        const serviceDefault = {
            name: '',
            mustPay: null, // Boolean - If the tenant must pay for the service
            individuallyMetred: null, // Boolean - If the tenant must pay for the service then is the service individually metred
            costApportionment: '', // If the service is not individually metred then what must the tenant pay for
            directToSupplier: null, // Boolean - If the payment method is direct to the supplier
            howToPay: '', // If the payment method is not direct to the supplier then how must they pay for the service
            locked: false // Locked services cannot be removed
        };
        push('premiseServices.premiseServices', serviceDefault);
    }

    return (
        <React.Fragment>
            {/* TODO: dhanushka need to investigate why we can't put tooltip inside .additional class (css issue) */}
            <ReactTooltip id="breakLeaseToolTip" class="tooltip" place="bottom" effect="solid" globalEventOff="wheel">
                <p className="break-lease-text">
                    <p>If you select yes, we will add the following clause to the agreement:</p>
                    <br />
                    <p>
                        Should the tenant/s vacate the property prior to the end of fixed term, for reasons other than
                        provided in accordance with the RTA or this agreement, the tenant acknowledges and agrees that
                        they will be liable for:
                    </p>
                    <ol>
                        <li>
                            If the lease is terminated within the first half of the Agreement, the tenant will be liable
                            for one weeks rent for administrative costs along with compensation capped at a maximum of 6
                            weeks’ rent; OR
                        </li>
                        <li>
                            If the lease is terminated within the second half of the Agreement, the tenant will be
                            liable for two-thirds of one weeks rent for administrative costs along with compensation
                            capped at a maximum of 4 weeks’ rent.
                        </li>
                    </ol>
                </p>
            </ReactTooltip>
            <div className="additional">
                <Form
                    onSubmit={submitForm}
                    initialValues={additional}
                    mutators={{
                        ...arrayMutators
                    }}
                >
                    {({
                        handleSubmit,
                        values,
                        form,
                        form: {
                            mutators: { push }
                        }
                    }) => {
                        return (
                            <form id={ADDITIONAL_FORM} onSubmit={handleSubmit}>
                                <FormSpy
                                    subscription={{ values: true }}
                                    onChange={state => handleFormDirtyChange(state.values, form)}
                                />
                                {!isLeaseTypeCommercialLease(leaseType) && location === NSW_STATE && (
                                    <NswAdditional
                                        values={values}
                                        addPet={addPet}
                                        allowPets={allowPets}
                                        clearPets={clearPets}
                                        push={push}
                                        additional={additional}
                                        location={location}
                                        loggedInUser={loggedInUser}
                                    />
                                )}
                                {location === QLD_STATE && (
                                    <QldAdditional
                                        values={values}
                                        addPet={addPet}
                                        allowPets={allowPets}
                                        clearPets={clearPets}
                                        addAdditionalService={addAdditionalService}
                                        push={push}
                                        additional={additional}
                                        setAdditional={setAdditional}
                                        location={location}
                                        loggedInUser={loggedInUser}
                                    />
                                )}
                                {location === VIC_STATE && (
                                    <VicAdditional
                                        values={values}
                                        addPet={addPet}
                                        allowPets={allowPets}
                                        setNoOwnerRights={setNoOwnerRights}
                                        clearPets={clearPets}
                                        push={push}
                                        additional={additional}
                                        form={form}
                                        location={location}
                                    />
                                )}
                                {location === ACT_STATE && (
                                    <ActAdditional
                                        values={values}
                                        addPet={addPet}
                                        allowPets={allowPets}
                                        clearPets={clearPets}
                                        push={push}
                                        additional={additional}
                                        location={location}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Form>
            </div>
        </React.Fragment>
    );
});

export default memo(Additional);
