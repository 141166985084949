import { useEffect, useRef } from 'react';
import { YES_VALUE, NO_VALUE } from '../config';

export const getNotificationMapId = agency => {
    return 'notifications_' + agency.id;
};
export const getAgreementsMapId = agency => {
    return 'agreements_' + agency.id;
};
export const getDocumentMapId = agency => {
    return 'documents_' + agency.id;
};

export const getKeyMapId = agency => {
    return 'keys' + agency.id;
};

export const EVENT_ITEM_UPDATED = 'itemUpdated';
export const EVENT_ITEM_ADDED = 'itemAdded';
export const EVENT_ITEM_REMOVED = 'itemRemoved';
export const TWILIO_TIMED_OUT_ERROR_MESSAGE = "to 'cds.us1.twilio.com' timed out";

const usePrevious = (value, initialValue) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useEffectDebugger = (effectHook, dependencies, dependencyNames = []) => {
    const previousDeps = usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce((accum, dependency, index) => {
        if (dependency !== previousDeps[index]) {
            const keyName = dependencyNames[index] || index;
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency
                }
            };
        }

        return accum;
    }, {});

    if (Object.keys(changedDeps).length) {
        console.log('[use-effect-debugger] ', changedDeps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effectHook, dependencies);
};

export const mapBooleanToYesNo = value => (typeof value === 'boolean' ? (value ? YES_VALUE : NO_VALUE) : null);

export const mapUtilityServices = utilityServices => {
    if (!utilityServices) {
        return;
    }

    let premiseServices;
    let separatedWaterUsage;

    if (utilityServices?.premiseServices?.premiseServices) {
        premiseServices = utilityServices.premiseServices.premiseServices.map(service => {
            return {
                ...service,
                mustPay: mapBooleanToYesNo(service.mustPay),
                individuallyMetred: mapBooleanToYesNo(service.individuallyMetred),
                directToSupplier: mapBooleanToYesNo(service.directToSupplier)
            };
        });
    }

    if (utilityServices?.separatedWaterUsage !== null) {
        separatedWaterUsage = mapBooleanToYesNo(utilityServices?.separatedWaterUsage);
    }

    return {
        premiseServices: { premiseServices },
        separatedWaterUsage
    };
};
