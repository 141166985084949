import React, { memo, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import * as Lease from '../../../reducers/lease';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../selectors/lease';
import { getSalesExpensesAndCharges } from '../../../selectors/lease/salesLease';

import { ChargesGroup } from '../../../components/lease/mainScreen/common/sales/ChargesGroup';
import '../../../sass/expenses.scss';
import plusIcon from "../../../../assets/images/icons/plus.svg?url";
import { getLabel } from '../../../utils/labelUtils';
import { isLeaseLocationQLD } from '../../../utils/agreementUtils';
import { FormTextRegular } from '../../../components/form/FormText';

const EXPENSES_CHARGES_FORM = 'SalesExpensesAndChargesForm';

const ExpensesAndCharges = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxSalesExpensesAndCharges = useSelector(getSalesExpensesAndCharges);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);

    const [salesExpensesAndCharges, setSalesExpensesAndCharges] = useState(reduxSalesExpensesAndCharges || {});

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(EXPENSES_CHARGES_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const submitForm = values => {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        return updateSalesExpensesAndCharges(values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SALES_EXPENSES_CHARGES_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.expensesAndCharges')) {
                    return error.response.data.errors.expensesAndCharges;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    };

    const updateSalesExpensesAndCharges = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/expenses-and-charges`, data);
    };

    const removeCharge = (values, index) => {
        let newCharges = cloneDeep(values);
        newCharges.chargeList = [...newCharges.chargeList.slice(0, index), ...newCharges.chargeList.slice(index + 1)];
        setSalesExpensesAndCharges(newCharges);
    };

    return (
        <div className="expenses">
            <Form
                onSubmit={submitForm}
                initialValues={salesExpensesAndCharges}
                mutators={{
                    ...arrayMutators
                }}
            >
                {({
                    handleSubmit,
                    values,
                    form,
                    form: {
                        mutators: { push }
                    }
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate id={EXPENSES_CHARGES_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="text">
                                <p>{getLabel('otherExpenses', location, leaseType)}</p>
                            </div>
                            <div>
                                {isLeaseLocationQLD(location) && (
                                    <FormTextRegular
                                        name="limitOnCostsOfRepairs"
                                        label="The maximum value of repairs and maintenance to be paid by the agent without prior approval by the client is"
                                        required
                                    />
                                )}
                                <FieldArray name="chargeList" initialValue={salesExpensesAndCharges.chargeList}>
                                    {({ fields }) => (
                                        <React.Fragment>
                                            {fields.map((name, index) => {
                                                return (
                                                    <ChargesGroup
                                                        index={index}
                                                        key={`${index}`}
                                                        removeCharge={index => removeCharge(values, index)}
                                                        location={location}
                                                        leaseType={leaseType}
                                                    />
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                                <div className="button">
                                    <button
                                        className="mobile add-item"
                                        type="button"
                                        onClick={() =>
                                            push('chargeList', {
                                                service: '',
                                                estimate: isLeaseLocationQLD(location) ? '$' : '',
                                                dueDate: null
                                            })
                                        }
                                    >
                                        <img src={plusIcon} className="for-sm-modal" />
                                        Add Charge
                                    </button>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(ExpensesAndCharges);
